<template>
  <el-card style="overflow: scroll;">
    <el-tabs v-model="activeName" type="card" :before-leave="handleBeforeLeave">
      <el-tab-pane label="1.基础信息" name="one">
        <!-- :rules="oneRules" -->
        <el-form
          :model="oneForm"
          ref="oneForm"
          :rules="oneFormRules"
          label-width="100px"
          class="oneForm"
        >
          <el-form-item label="商品属性" prop="property">
            <el-radio-group v-model="oneForm.property" size="small">
              <el-radio :label="2">实物商品</el-radio>
              <el-radio :label="1">服务类</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="商品名称" prop="name">
            <el-input
              v-model="oneForm.name"
              placeholder="请输入商品名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="所属店铺" prop="shopId">
            <el-select
              :filterable="true"
              :clearable="true"
              @change="handleChangeShop"
              v-model="oneForm.shopId"
              :disabled="userInfo.type == 3"
              placeholder="请选择所属店铺"
            >
              <el-option
                v-for="item in allChildList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="商品货号" v-if="goodsAutoNumber" prop="code">
            <el-select
              :filterable="true"
              :clearable="true"
              v-model="oneForm.code"
              placeholder="请选择商品货号"
            >
              <el-option
                v-for="item in goodsGoodsList"
                :key="item.id"
                :label="item.goodsName"
                :value="item.goodsCode"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="对谁可见" prop="scopes">
            <el-checkbox-group v-model="oneForm.scopes">
              <el-checkbox :label="1">企业采购商</el-checkbox>
              <el-checkbox :label="2">企业个人</el-checkbox>
              <el-checkbox :label="3">顾客</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="限购" prop="serviceIds">
            <el-radio-group v-model="oneForm.limit" size="small">
              <el-radio :label="-1">不限购</el-radio>
              <el-radio :label="0">限购</el-radio>
            </el-radio-group>
            <el-input-number
              v-if="oneForm.limit != -1"
              style="margin-left: 20px;"
              v-model="oneForm.limitCount"
              :min="1"
              :max="99999"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="商品服务" prop="serviceIds">
            <el-checkbox-group v-model="oneForm.serviceIds">
              <el-checkbox
                v-for="(item, index) in serviceTagList"
                :key="index"
                :label="item.id"
              >
                {{ item.name }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="商品标签" :required="true">
            <div class="goodsTagWrap">
              <div class="first">
                <div
                  class="first-item"
                  :class="{ active: selectedGoodsTagIndex == index }"
                  v-for="(item, index) in goodsTagList"
                  :key="index"
                  @click="handleSelectGoodsFirstTag(item, index)"
                >
                  {{ item.name }}
                  <div class="count">{{ item.count }}</div>
                </div>
              </div>
              <div class="second" v-if="goodsTagList[selectedGoodsTagIndex]">
                <div
                  class="second-item"
                  :class="{ active: item.checked }"
                  v-for="(item, index) in goodsTagList[selectedGoodsTagIndex]
                    .child"
                  :key="index"
                  @click="
                    handleSelectGoodsSecondTag(
                      goodsTagList[selectedGoodsTagIndex],
                      item,
                    )
                  "
                >
                  {{ item.name }}
                </div>
              </div>
            </div>
          </el-form-item>
          <!-- <el-form-item label="物流方式" prop="shopId">
            <el-radio-group v-model="oneForm.logisticsMode">
              <el-radio-button label="1">自提</el-radio-button>
              <el-radio-button label="2">快递</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="自提日期限制" prop="serviceIds">
            <el-radio-group v-model="oneForm.pickUpType" size="small">
              <el-radio :label="1">不限制</el-radio>
              <el-radio :label="2">指定截至日期</el-radio>
              <el-radio :label="3">购买后</el-radio>
            </el-radio-group>
            <el-input-number
              style="margin-left: 20px;"
              v-model="oneForm.pickUpAfterDay"
              :min="1"
              :max="99999"
            ></el-input-number>
          </el-form-item>
          <el-form-item
            v-if="oneForm.pickUpType == 2"
            label="自提截至日期"
            prop="serviceIds"
          >
            <el-date-picker
              v-model="oneForm.pickUpDeadline"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            ></el-date-picker>
          </el-form-item> -->
        </el-form>
        <div class="footer">
          <el-button @click="handleChangeTab('two')" type="primary">
            下一步
          </el-button>
        </div>
      </el-tab-pane>
      <el-tab-pane label="2.库存" name="two">
        <!-- <div class="wrap">
        </div> -->
        <el-form
          :model="oneForm"
          ref="towRuleForm"
          label-width="100px"
          class="twoForm"
        >
          <el-form-item label="商品类别">
            <el-cascader
              v-model="twoForm.goodsTypeId"
              :options="goodsTypeList"
              @change="handleGoodTypeChange"
              filterable
              clearable
              :props="{
                emitPath: false,
                value: 'id',
                label: 'name',
                children: 'child',
              }"
            ></el-cascader>
          </el-form-item>
          <el-form-item
            v-for="(item, index) in tagDetails"
            :key="index"
            :label="item.tag"
          >
            <el-tag
              v-for="tag in item.content"
              :key="tag"
              closable
              :disable-transitions="false"
              @close="handleClose(tag, index)"
            >
              {{ tag }}
            </el-tag>
            <el-input
              class="input-new-tag"
              v-if="item.inputVisible"
              v-model="inputValue"
              ref="saveTagInput"
              size="mini"
              @keyup.enter.native="(e) => handleInputConfirm(e, index)"
              @blur="(e) => handleInputConfirm(e, index)"
            ></el-input>
            <el-button
              v-else
              class="button-new-tag"
              size="mini"
              @click="showInput(index)"
            >
              添加标签
            </el-button>
          </el-form-item>
        </el-form>
        <el-table height="520" :data="goodsSkusList" style="width: 100%;">
          <el-table-column prop="specification" label="规格"></el-table-column>
          <el-table-column prop="externalSkuNo" label="商品编码" width="400">
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.externalSkuNo"
                placeholder="请输入商品编码"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="saleAmount" label="售价(单位元)">
            <template slot-scope="scope">
              <el-input-number
                controls-position="right"
                v-model="scope.row.saleAmount"
                :min="0"
                :precision="2"
                placeholder="请输入商品售价"
              ></el-input-number>
            </template>
          </el-table-column>
          <el-table-column prop="originalAmount" label="原价(选填)(单位元)">
            <template slot-scope="scope">
              <el-input-number
                controls-position="right"
                v-model="scope.row.originalAmount"
                :min="0"
                :precision="2"
                placeholder="请输入商品原价"
              ></el-input-number>
            </template>
          </el-table-column>
          <el-table-column prop="inventoryCount" label="库存">
            <template slot-scope="scope">
              <el-input-number
                controls-position="right"
                v-model="scope.row.inventoryCount"
                :min="0"
                placeholder="请输入商品库存"
              ></el-input-number>
            </template>
          </el-table-column>
          <el-table-column prop="inventoryCount" label="封面图片">
            <template slot-scope="scope">
              <div
                class="image-container"
                @mouseenter="hover = true"
                @mouseleave="hover = false"
              >
                <template v-if="scope.row.specificationImage">
                  <img
                    style="width: 100%; height: 100%;"
                    :src="scope.row.specificationImage"
                    alt="Uploaded Image"
                    class="uploaded-image"
                  />
                </template>
                <div
                  @click="handleAddGoodsImg(scope.row)"
                  v-else
                  class="placeholder"
                >
                  +
                </div>
                <div
                  class="overlay"
                  v-if="scope.row.specificationImage && hover"
                  @click="removeImage(scope.row, scope.$index)"
                >
                  <i class="el-icon-delete"></i>
                </div>
              </div>
              <!-- <div class="wrap">
                <div style="width: 120px;height:120px;" v-if="scope.row.specificationImage">
                  <div class="img" style="width: 120px;height:120px;">
                    <img style="width: 100%;height: 100%;" :src="scope.row.specificationImage" alt="">
                  </div>
                </div>
                <div style="width: 120px;height:120px;line-height: 120px;text-align: center;font-size: 28upx;" v-if="!scope.row.specificationImage">
                  <div class="plus" @click="handleAddGoodsImg(scope.row)">+</div>
                </div>
              </div> -->
            </template>
          </el-table-column>
        </el-table>
        <div class="footer">
          <el-button @click="handleChangeTab('one')">上一步</el-button>
          <el-button @click="handleChangeTab('three')" type="primary">
            下一步
          </el-button>
        </div>
      </el-tab-pane>
      <el-tab-pane label="3.商品详情" name="three">
        <el-form
          class="threeForm"
          ref="threeForm"
          :model="threeForm"
          label-width="80px"
        >
          <el-form-item label="封面图片" :required="true">
            <div class="wrap">
              <div
                class="imgWrap"
                v-for="(item, index) in threeForm.covers"
                :key="index"
              >
                <template>
                  <div class="img">
                    <img :src="item" alt="" />
                  </div>
                  <div class="delete" @click="handleDeleteCover(index)">X</div>
                </template>
              </div>
              <div class="imgWrap" v-if="threeForm.covers.length < 5">
                <template>
                  <div class="plus" @click="handleAddCover()">+</div>
                </template>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="商品详情描述">
            <Editor :detail.sync="threeForm.detail" />
          </el-form-item>
        </el-form>
        <div class="footer">
          <el-button @click="handleChangeTab('two')">上一步</el-button>
          <el-button @click="handleSubmit()" type="primary">
            提交
          </el-button>
        </div>
      </el-tab-pane>
    </el-tabs>
    <MaterialDialog
      :visible.sync="materialVisible"
      @handleTypeImg="handleTypeImg"
    />
  </el-card>
</template>
<script>
import Vue from 'vue'
import Editor from '../../../components/editor.vue'
import MaterialDialog from '../../../components/materialDialog/index'
import { debounce } from 'lodash'

export default {
  name: 'ShopEdit',
  components: {
    Editor,
    MaterialDialog,
  },
  data() {
    return {
      activeName: 'one',
      canChange: false,
      // 第一个表单-基础信息
      oneForm: {
        property: 2,
        name: '',
        shopId: '',
        limit: -1,
        limitCount: 0,
        scopes: [],
        serviceIds: [],
        code: '',
      },
      oneFormRules: {
        property: this.$formValidator.empty('商品属性', 'change', 'number'),
        name: this.$formValidator.empty('商品名称'),
        shopId: this.$formValidator.empty('商品属性', 'change', 'number'),
        scopes: this.$formValidator.empty('对谁可见', 'change', 'array'),
        code: this.$formValidator.empty('商品属性', 'change')
      },
      // 第二个表单-库存
      twoForm: {
        goodsTypeId: '',
      },
      hover: false,
      /* 选择店铺 */
      childList: [],
      allChildList: [],
      allShopList: [],
      // 商品服务
      serviceTagList: [],
      // 商品标签
      goodsTagList: [],
      selectedGoodsTagIndex: 0,
      // 商品类别
      goodsTypeList: [],
      tagDetails: [],
      inputVisible: false,
      inputValue: '',
      // 库存列表
      goodsSkusList: [],
      // 第三个表单-商品详情
      threeForm: {
        covers: [],
        detail: '',
      },
      materialVisible: false,
      // 步骤
      step: 1,
      userInfo: {},
      goodsId: null, // 新增生成商品id
      goodsDetail: {},
      addImgType: '',
      selectRow: {},
      goodsAutoNumber: false,
      goodsGoodsList: [],
      userInfo: {},
    }
  },
  async created() {
    await this.getGoodsTagList()
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    if (this.$route.params.id) {
      this.handleInitDetail(this.$route.params.id)
      this.handleChangeShop(this.userInfo.shopId)
    }
    this.getShopList()
    this.handleGetGoodsTypeList()
    this.getGoodsAutoNumberConfig()
  },
  watch: {
    oneForm: {
      deep: true,
      handler: debounce(function (val) {
        if (this.step != 1) {
          this.handleGoodsUpdateStepOne()
        }
      }, 30000),
    },
    goodsSkusList: {
      deep: true,
      handler: debounce(function (val) {
        if (this.step >= 2) {
          this.handleGoodsUpdateStepTwo()
        }
      }, 30000),
    },
    threeForm: {
      deep: true,
      handler: debounce(function (val) {
        if (this.step >= 3) {
          this.handleGoodsUpdateStepThree()
        }
      }, 30000),
    },
  },
  methods: {
    removeImage(row, idx) {
      this.goodsSkusList = this.goodsSkusList.map((item, index) => {
        if (index == idx) {
          item.specificationImage = ''
        }
        return item
      })
      console.log('index', index)
      console.log('row', row)
    },
    handleBeforeLeave(a, b) {
      return new Promise((resolve, reject) => {
        if (!this.canChange) {
          reject(false)
        } else {
          resolve(true)
          this.canChange = false
        }
      })
    },
    async getGoodsAutoNumberConfig() {
      const { data, code, msg } = await this.$api.sysapi.getMarketConfigType({
        configType: 'GOODS_AUTO_NUMBER',
      })
      if (code == 200) {
        this.goodsAutoNumber = data.configValue == 'false'
      }
    },
    async handleGetGoodsGoodsList() {
      const shop = this.allChildList.find(
        (item) => item.id == this.oneForm.shopId,
      )
      const postData = {
        pageNum: 1,
        pageSize: -1,
        shopCode: shop.shopCode,
      }
      const {
        data,
        code,
        msg,
      } = await this.$api.shopManageApi.getGoodsGoodsList(postData)
      if (code == 200) {
        this.goodsGoodsList = data
      }
    },
    /* 编辑-详情 */
    async handleInitDetail(id) {
      const {
        data,
        code,
        msg,
      } = await this.$api.shopManageApi.businessGoodsDetail({ id })
      if (code == 200) {
        let serviceIds = []
        data.goodsServices.forEach((item) => {
          serviceIds.push(item.id)
        })
        this.oneForm = {
          property: data.property,
          name: data.name,
          shopId: data.shopId,
          limit: data.limitCount == -1 ? -1 : 0,
          limitCount: data.limitCount,
          scopes: data.scopes,
          serviceIds,
          code: data.code,
        }
        this.getItemList()
        this.twoForm = {
          goodsTypeId: data.goodsTypeId,
        }
        this.goodsTagList = this.goodsTagList.map((item) => {
          if (item.child && data.goodsTags) {
            item.child.forEach((child) => {
              data.goodsTags.forEach((tag) => {
                if (tag.child) {
                  child.checked = !!tag.child.find((e) => e.id === child.id)
                }
              })
            })
          }
          item.count = item.child.filter((child) => child.checked).length
          return item
        })
        this.tagDetails = data.tagDetails
        this.goodsSkusList = data.goodsSkus
        this.step = data.step
        this.goodsDetail = data
        this.threeForm = {
          covers: data.covers,
          detail: data.detail,
        }
        console.log('detail', this.threeForm);
      }
    },
    async handleChangeTab(activeName) {
      try {
        if (this.step == 1 && activeName == 'two') {
          // 第一页下一步
          if (this.$route.params.id) {
            // 如果是编辑， 不调用接口
            await this.handleGoodsUpdateStepOne()
          } else {
            // 如果是新增,先调用新增获取id在调用提交商品基础信息
            await this.handleGoodsInsertStepOne()
            console.log(2)
            await this.handleGoodsSubmitStepOne()
            console.log(3)
          }
          this.step = 2
        } else if (this.step == 2 && activeName == 'three') {
          if (this.$route.params.id) {
            // 如果是编辑， 不调用接口
            const allContentNotEmpty = this.tagDetails.every(
              (item) => item.content.length > 0,
            )
            if (!allContentNotEmpty) {
              this.$message.warning('请维护商品规格')
              return
            }
            await this.handleGoodsUpdateStepTwo()
          } else {
            // 如果是新增,调用提交保存商品库存信息接口
            const allContentNotEmpty = this.tagDetails.every(
              (item) => item.content.length > 0,
            )
            if (!allContentNotEmpty) {
              this.$message.warning('请维护商品规格')
              return
            }
            await this.handleGoodsSubmitStepTwo()
          }
          this.step = 3
        } else if (this.step == 3 && activeName == 'three') {
          if (this.$route.params.id) {
            // 如果是编辑,不调用接口
            const allContentNotEmpty = this.tagDetails.every(
              (item) => item.content.length > 0,
            )
            if (!allContentNotEmpty) {
              this.$message.warning('请维护商品规格')
              return
            }
            await this.handleGoodsUpdateStepTwo()
          } else {
            // 如果是新增,调用保存商品详情信息并提审
            const allContentNotEmpty = this.tagDetails.every(
              (item) => item.content.length > 0,
            )
            if (!allContentNotEmpty) {
              this.$message.warning('请维护商品规格')
              return
            }
            await this.handleGoodsUpdateStepTwo()
          }
          // if (this.$route.params.id) { // 如果是编辑,不调用接口
          //   await this.handleGoodsUpdateStepThree()
          // } else { // 如果是新增,调用保存商品详情信息并提审
          //   await this.handleGoodsSubmitStepThree()
          // }
        } else if (this.step == 3 && activeName == 'two') {
          await this.handleGoodsUpdateStepOne()
        } else if (this.step == 3 && activeName == 'three') {
          await this.handleGoodsUpdateStepTwo()
        }
        else if (this.step == 4 && activeName == 'two') {
          await this.handleGoodsUpdateStepOne()
        } else if (this.step == 4 && activeName == 'three') {
          await this.handleGoodsUpdateStepTwo()
        }
        this.canChange = true
        this.activeName = activeName
      } catch (error) {
        console.log('error', error)
      }
    },
    handleSubmit() {
      if (this.step == 3) {
        this.handleGoodsSubmitStepThree()
      } else if (this.step == 4) {
        if (this.goodsDetail.status == 2) {
          this.handleGoodsSubmitStepThree()
        } else {
          this.handleGoodsUpdateStepThree()
        }
      }
    },
    // 新增商品基础信息
    async handleGoodsInsertStepOne() {
      return new Promise((resolve, reject) =>  {
        this.$refs.oneForm.validate(async (valid) => {
          if (valid) {
            const {
              name,
              property,
              scopes,
              serviceIds,
              shopId,
              limit,
              limitCount,
              code,
            } = this.oneForm
            const goodsTags = this.goodsTagList
              .filter((item) => item.count > 0)
              .map((item) => ({
                id: item.id,
                child: item.child.filter((child) => child.checked),
              }))
            const postData = {
              property,
              name,
              shopId: shopId ? shopId : this.userInfo.shopId,
              scopes,
              limitCount: limit == 0 ? limitCount : -1,
              serviceIds,
              goodsTags,
              code,
            }
            const {
              code: rescode,
              data,
              msg,
            } = await this.$api.shopManageApi.goodsInsertStepOne(postData)
            if (rescode == 200) {
              resolve(true)
              this.goodsId = data
            } else {
              reject(false)
            }
          } else {
            reject(false)
          }
        })
      })
    },
    // 修改商品基础信息
    async handleGoodsUpdateStepOne() {
      return new Promise((resolve, reject) => {
        this.$refs.oneForm.validate(async (valid) => {
          if (valid) {
            const {
              name,
              property,
              scopes,
              serviceIds,
              shopId,
              limit,
              limitCount,
              code,
            } = this.oneForm
            const goodsTags = this.goodsTagList
              .filter((item) => item.count > 0)
              .map((item) => ({
                id: item.id,
                child: item.child.filter((child) => child.checked),
              }))
            const postData = {
              id: this.$route.params.id || this.goodsId,
              property,
              name,
              shopId,
              scopes,
              limitCount: limit == 0 ? limitCount : -1,
              serviceIds,
              goodsTags,
              code,
            }
            const {
              code: rescode,
              data,
              msg,
            } = await this.$api.shopManageApi.goodsUpdateStepOne(postData)
            if (rescode == 200) {
              // this.goodsId = data
              resolve(true)
              // this.canChange = true
              this.$message.success('保存成功')
            } else {
              this.$message.error('保存失败:' + msg)
              resolve(true)
            }
          } else {
            reject(false)
          }
        })
      })
    },
    // 提交商品基础信息
    async handleGoodsSubmitStepOne() {
      return new Promise((resolve, reject) => {
        this.$refs.oneForm.validate(async (valid) => {
          if (valid) {
            const {
              name,
              property,
              scopes,
              serviceIds,
              shopId,
              limit,
              limitCount,
              code,
            } = this.oneForm
            const goodsTags = this.goodsTagList
              .filter((item) => item.count > 0)
              .map((item) => ({
                id: item.id,
                child: item.child.filter((child) => child.checked),
              }))
            const postData = {
              id: this.$route.params.id || this.goodsId,
              property,
              name,
              shopId: shopId ? shopId : this.userInfo.shopId,
              scopes,
              limitCount: limit == 0 ? limitCount : -1,
              serviceIds,
              goodsTags,
              code,
            }
            const {
              code: rescode,
              data,
              msg,
            } = await this.$api.shopManageApi.goodsSubmitStepOne(postData)
            if (rescode == 200) {
              resolve(true)
              // this.canChange = true
              this.$message.success('保存成功')
            } else {
              this.$message.error('保存失败:' + msg)
              reject(false)
            }
          } else {
            reject(false)
          }
        })
      })
    },
    // 修改商品库存信息
    async handleGoodsUpdateStepTwo() {
      return new Promise(async (resolve, reject) => {
        const postData = {
          goodsTypeId: this.twoForm.goodsTypeId,
          id: this.$route.params.id || this.goodsId,
          tagDetails: this.tagDetails,
          goodsSkus: this.goodsSkusList,
        }
        const {
          code,
          data,
          msg,
        } = await this.$api.shopManageApi.goodsUpdateStepTwo(postData)
        if (code == 200) {
          resolve(true)
          // this.canChange = true
          // this.$message.success('保存成功')
        } else {
          reject(false)
          // this.$message.error('保存失败:' + msg)
        }
      })
    },
    // 保存商品库存信息
    async handleGoodsSubmitStepTwo() {
      // const allContentNotEmpty = this.tagDetails.every(item => item.content.length > 0)
      // if (!allContentNotEmpty) {
      //   this.$message.warning('请维护商品规格')
      //   return
      // }
      return new Promise(async (resolve, reject) => {
        const postData = {
          goodsTypeId: this.twoForm.goodsTypeId,
          id: this.$route.params.id || this.goodsId,
          tagDetails: this.tagDetails,
          goodsSkus: this.goodsSkusList,
        }
        const {
          code,
          data,
          msg,
        } = await this.$api.shopManageApi.goodsSubmitStepTwo(postData)
        if (code == 200) {
          resolve(true)
          // this.canChange = true
          this.$message.success('保存成功')
        } else {
          this.$message.error('保存失败:' + msg)
          reject(false)
        }
      })
    },
    // 修改商品详情信息
    async handleGoodsUpdateStepThree() {
      // const allContentNotEmpty = this.tagDetails.every(item => item.content.length > 0)
      // if (!allContentNotEmpty) {
      //   this.$message.warning('请维护商品规格')
      //   return
      // }
      return new Promise(async (resolve, reject) => {
        const postData = {
          covers: this.threeForm.covers,
          detail: this.threeForm.detail,
          id: this.$route.params.id || this.goodsId,
        }
        const {
          code,
          data,
          msg,
        } = await this.$api.shopManageApi.goodsUpdateStepThree(postData)
        if (code == 200) {
          resolve(true)
          // this.canChange = true
          // this.$message.success('保存成功')
        } else {
          reject(false)
          // this.$message.error('保存失败:' + msg)
        }
      })
    },
    // 保存商品详情信息并提审
    async handleGoodsSubmitStepThree() {
      return new Promise(async (resolve, reject) => {
        if (this.threeForm.covers.length == 0) {
          this.$message.error('请上传封面图片')
          reject(false)
          return
        }
        const postData = {
          covers: this.threeForm.covers,
          detail: this.threeForm.detail,
          id: this.$route.params.id || this.goodsId,
        }
        const {
          code,
          data,
          msg,
        } = await this.$api.shopManageApi.goodsSubmitStepThree(postData)
        if (code == 200) {
          this.$message.success('保存成功')
          this.$router.back()
          resolve(true)
        } else {
          this.$message.error('保存失败:' + msg)
          reject(false)
        }
      })
    },
    handleAddGoodsImg(row) {
      this.materialVisible = true
      this.addImgType = 'addGoodImg'
      this.selectRow = row
    },
    // 标签列表
    async getGoodsTagList() {
      const {
        data,
        code,
        msg,
      } = await this.$api.shopManageApi.getGoodsTagList()
      console.log('list')
      if (code === 200) {
        this.goodsTagList = data.map((item) => {
          Vue.set(item, 'count', 0)
          item.child.forEach((child) => {
            Vue.set(child, 'checked', false)
          })
          return item
        })
      }
    },
    handleSelectGoodsFirstTag(row, index) {
      this.selectedGoodsTagIndex = index
    },
    handleSelectGoodsSecondTag(firstLevel, child) {
      child.checked = !child.checked
      if (!firstLevel.count) {
        Vue.set(firstLevel, 'count', 0)
      }
      firstLevel.count += child.checked ? 1 : -1
      console.log('firstLevel: ', firstLevel)
    },
    // 服务列表
    async getItemList() {
      const params = {
        pageNum: 1,
        pageSize: -1,
        shopId: this.oneForm.shopId,
        name: '',
      }
      const {
        data,
        code,
        msg,
      } = await this.$api.shopManageApi.getServiceTagList(params)
      if (code === 200) {
        this.serviceTagList = data.list
        console.log('serviceTagList', this.serviceTagList)
      }
    },
    // 商品详情
    // 添加封面
    handleAddCover() {
      this.addImgType = 'addCoverImg'
      this.materialVisible = true
    },
    handleTypeImg(url) {
      if (!this.addImgType) return
      if (this.addImgType == 'addGoodImg') {
        this.goodsSkusList = this.goodsSkusList.map((item) => {
          if (item.specification == this.selectRow.specification) {
            item.specificationImage = url
          }
          return item
        })
        this.addImgType = ''
        this.selectRow = {}
      } else {
        this.threeForm.covers.push(url)
      }
    },
    // 删除封面
    handleDeleteCover(target) {
      this.threeForm.covers.splice(target, 1)
    },
    /* 库存 */
    // 商品类别
    async handleGetGoodsTypeList() {
      const {
        code,
        msg,
        data,
      } = await this.$api.shopManageApi.getGoodsTypeList()
      if (code == 200) {
        this.goodsTypeList = this.removeEmptyChildren(data)
      } else {
        this.$message.error(msg)
      }
    },
    // 类型变化，tag也变化
    handleGoodTypeChange(id) {
      const tem = this.findItemById(this.goodsTypeList, id)
      let tagDetails = []
      tem.tags.forEach((item) => {
        tagDetails.push({
          tag: item,
          inputVisible: false,
          content: [],
        })
      })
      this.tagDetails = tagDetails
      this.goodsSkusList = []
    },
    handleClose(tag, target) {
      this.tagDetails = this.tagDetails.map((item, index) => {
        if (target == index) {
          item.content.splice(item.content.indexOf(tag), 1)
        }
        return item
      })
      // 使用filter()方法过滤掉specification属性中/后面是tag的项
      this.goodsSkusList = this.goodsSkusList.filter((item) => {
        // 获取specification字段，并通过'/'分割
        const parts = item.specification.split(' / ')
        // 检查分割后第target部分是否不等于'tag'
        return parts[target] !== tag
      })
      this.handleGoodsUpdateStepTwo()
    },

    showInput(target) {
      this.tagDetails = this.tagDetails.map((item, index) => {
        if (target == index) {
          item.inputVisible = true
        }
        return item
      })
      this.$nextTick((_) => {
        this.$refs.saveTagInput[0].$refs.input.focus()
      })
    },

    handleInputConfirm(e, target) {
      let inputValue = this.inputValue
      if (inputValue) {
        this.tagDetails = this.tagDetails.map((item, index) => {
          if (target == index) {
            if (item.content.indexOf(inputValue) != -1) {
              this.$message.error('该标签已存在')
            } else {
              item.content.push(inputValue)
            }
          }
          item.inputVisible = false
          return item
        })
      }
      this.inputValue = ''
      const allContentsNotEmpty = this.tagDetails.every(
        (item) => item.content && item.content.length > 0,
      )
      if (allContentsNotEmpty) {
        console.log('allContentsNotEmpty', allContentsNotEmpty)
        let resultList = []
        if (this.tagDetails.length == 1) {
          const firstContent = this.tagDetails[0].content
          firstContent.forEach((firstItem) => {
            resultList.push({
              externalSkuNo: '', // 外部商品sku编码
              inventoryCount: 0, // 库存
              originalAmount: 0, // 原价
              saleAmount: 0, // 售价
              specification: firstItem, // 规格
              specificationImage: '',
              status: 1,
            })
          })
        } else if (this.tagDetails.length == 2) {
          const firstContent = this.tagDetails[0].content
          const secondContent = this.tagDetails[1].content
          firstContent.forEach((firstItem) => {
            secondContent.forEach((secondItem) => {
              resultList.push({
                externalSkuNo: '', // 外部商品sku编码
                inventoryCount: 0, // 库存
                originalAmount: 0, // 原价
                saleAmount: 0, // 售价
                specification: `${firstItem} / ${secondItem}`, // 规格
                specificationImage: '',
                status: 1,
              })
            })
          })
        }
        resultList.forEach((temp) => {
          const existingItem = this.goodsSkusList.find(item => item.specification == temp.specification)
          if (existingItem) {
          } else {
            this.goodsSkusList.push(temp)
          }
        })
        // this.goodsSkusList = resultList
        console.log('goodsSkusList', this.goodsSkusList)
      }
    },
    findItemById(data, id) {
      let result = null // 用于存储找到的节点
      // 定义递归函数
      function search(items) {
        for (const item of items) {
          if (item.typeLevel === 3 && item.id === id) {
            result = item // 找到匹配的项，保存结果
            break // 结束循环
          }
          if (item.child && item.child.length > 0) {
            search(item.child) // 递归搜索子节点
            if (result) break // 如果已找到结果，则无需进一步搜索
          }
        }
      }
      search(data) // 开始递归搜索
      return result // 返回找到的节点，如果没有找到则为 null
    },
    // 处理商品类型第三级child为空的数据
    removeEmptyChildren(data) {
      data.forEach((item) => {
        if (item.child && item.child.length > 0) {
          this.removeEmptyChildren(item.child) // 递归处理子节点
        }
        // 如果当前节点的 typeLevel 是 3 并且 child 数组为空，则删除 child 属性
        if (item.typeLevel === 3 && item.child && item.child.length === 0) {
          delete item.child
        }
      })
      return data
    },
    // 店铺改变
    handleChangeShop(v) {
      if (!v) return
      this.oneForm.shopId = v
      this.getItemList()
      this.handleGetGoodsGoodsList()
    },
    // 店铺列表
    getShopList() {
      this.$api.shopapi.getShopList().then(({ data, code }) => {
        if (code == 200) {
          const { shopId, shopName, type, shopCode } = this.userInfo
          if (type == 2) {
            let childList = JSON.parse(JSON.stringify(data.childList))
            let allChildList = []
            data.childList.forEach((child) => {
              child.childList.forEach((shop) => {
                allChildList.push(shop)
              })
            })
            this.allChildList = allChildList
            // console.log('allChildList', allChildList)
            let allShop = {
              name: '全部',
              childList: allChildList,
            }
            childList.unshift(allShop)
            this.allShopList = JSON.parse(JSON.stringify(childList))
            this.childList = JSON.parse(JSON.stringify(childList))
            const child = [
              {
                id: data.id,
                name: data.name,
                childList: data.childList,
              },
            ]
            this.selectOption = child
          } else if (type == 3) {
            this.allChildList = [
              {
                name: shopName,
                id: shopId,
                shopCode,
              },
            ]
            this.oneForm.shopId = shopId
            this.getItemList()
            this.handleGetGoodsGoodsList()
          }
        }
      })
    },
    // 过滤店铺
    filterShop(val) {
      if (val) {
        this.childList.forEach((item) => {
          item.childList = item.childList.filter((shop) =>
            shop.name.includes(val),
          )
        })
      } else {
        this.childList = JSON.parse(JSON.stringify(this.allShopList))
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
.goodsTagWrap {
  .first {
    display: flex;
    .first-item {
      height: 36px;
      margin-right: 10px;
      margin-bottom: 10px;
      line-height: 36px;
      text-align: center;
      border-radius: 18px;
      padding: 0 20px;
      border: 1px solid #888889;
      position: relative;
      .count {
        position: absolute;
        top: -8px;
        right: 0;
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        border-radius: 10px;
        background-color: red;
      }
      &.active {
        background-color: #dadaff;
        border: none;
      }
    }
  }
  .second {
    display: flex;
    .second-item {
      font-size: 14px;
      margin-right: 10px;
      margin-bottom: 10px;
      text-align: center;
      border-radius: 10px;
      padding: 0 10px;
      border: 1px solid #888889;
      &.active {
        background-color: #8080ff;
        border: none;
      }
    }
  }
}
.twoForm {
}
.image-container {
  position: relative;
  width: 100px;
  height: 100px;
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: #ccc;

  .uploaded-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .placeholder {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 100px;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;
    .el-icon-delete {
      font-size: 24px;
    }
  }
}
.threeForm {
  .wrap {
    display: flex;
    overflow: visible;
    .imgWrap {
      width: 100px;
      height: 100px;
      border: 1px solid #f1f1f1;
      position: relative;
      margin-right: 10px;
      overflow: visible;
      .img {
        width: 100px;
        height: 100px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .plus {
        width: 100px;
        height: 100px;
        line-height: 100px;
        text-align: center;
        font-size: 32px;
      }
      .delete {
        width: 28px;
        height: 28px;
        border-radius: 14px;
        background-color: #fafafa;
        line-height: 28px;
        text-align: center;
        position: absolute;
        top: -5px;
        right: -10px;
        font-size: 22px;
        color: red;
      }
    }
  }
}
.footer {
  width: 100%;
  height: 80px;
  text-align: center;
  margin: 20px 0;
}
</style>
