<template>
  <div>
    <quill-editor
      ref="editor"
      v-model="content"
      class="myQuillEditor"
      :options="editorOption"
    />
    <el-upload
      action=""
      accept=".png,.jpeg,.jpg"
      list-type="picture"
      :show-file-list="false"
      :before-upload="beforeRichImageUpload"
      :http-request="(file) => handleRichImageUpload(file.file)"
      class="richImage"
    ></el-upload>
  </div>
</template>
<script>
// 引入样式
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor, Quill } from 'vue-quill-editor'
import ImageResize from 'quill-image-resize-module'
Quill.register('modules/imageResize', ImageResize)
const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  ['blockquote', 'code-block'],
  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
  [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
  [{ direction: 'rtl' }], // text direction
  [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],
  ['link', 'image'],
  ['clean'], // remove formatting button
]
const handlers = {
  image: function (v) {
    if (v) {
      const ele = document.querySelector('.richImage input')
      ele.click()
    } else {
      // this.quill.format('image', false)
    }
  },
}
export default {
  name: 'Editor',
  components: {
    quillEditor,
  },
  props: {
    detail: {
      type: String,
    },
  },
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: {
            container: toolbarOptions,
            handlers: handlers,
          },
          imageResize: {
            displayStyles: {
                backgroundColor: 'black',
                border: 'none',
                color: 'white'
            },
            modules: ['Resize', 'DisplaySize', 'Toolbar']
          }
        },
        placeholder: '请输入内容...',
      },
      content: '',
    }
  },
  created() {
    this.content = this.detail
    console.log('this.detail', this.detail)
  },
  methods: {
    handleRichImageUpload(file) {
      // if (this.disabled) return
      // const type = file.type.split('/')[1]
      const formData = new FormData()
      formData.append('file', file)
      this.$api.sysapi.uploadFile(formData, 'material').then((res) => {
        if (res.code == 200) {
          const ele = this.$refs.editor
          const quill = ele.quill
          let length = quill.getSelection().index
          quill.insertEmbed(length, 'image', res.data)
          quill.setSelection(length + 1)
        } else {
          this.$message.error('图片插入失败')
        }
      })
      // formData.append('name', file.uid + '.' + type)
      // formData.append('path', 'wxapp/advertiment/rich-image')
      // const config = {
      //   headers:
      //   {
      //     'Content-Type': 'multipart/form-data'
      //   }
      // }
      // cmsUploadFile(formData, config).then(res => {
      //   if (res.code == 200) {
      //     const ele = this.$refs.editor
      //     const quill = ele.quill
      //     let length = quill.getSelection().index
      //     quill.insertEmbed(length, 'image', res.data.url)
      //     quill.setSelection(length + 1)
      //   } else {
      //     this.$message.error('图片插入失败')
      //   }
      // })
    },
    beforeRichImageUpload(file) {
      const isJPGorPNG = file.type === 'image/jpeg' || file.type === 'image/png'  || file.type === 'image/jpg'
      if (!isJPGorPNG) {
        this.$message.error('上传图片只能是 JPG 或 PNG 格式!')
      }
      return isJPGorPNG
    },
  },
  watch: {
    content: {
      deep: true,
      immediate: true,
      handler(val) {
        console.log('val', val)
        this.$emit('update:detail', val)
      },
    },
    detail: {
      immediate: true,
      handler(val) {
        this.content = val
      },
    }
  },
}
</script>
