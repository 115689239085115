<template>
  <div>
    <el-dialog
      title="物料选择"
      :visible.sync="visible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="handleClose"
      @close="handleClose"
  
      width="1000px">
      <el-row :gutter="10">
        <el-col :span="6">
          <ul class="directoryList">
            <li 
              v-for="(item, index) in directoryList" 
              :key="index" 
              @click="handleSelectdirectory(item,index)" 
              class="directoryItem" 
              :class="{'active': selectedIndex == index}">
              <span>{{ item.name }}</span>
            </li>
          </ul>
        </el-col>
        <el-col class="rightWrap" :span="18">
          <div class="top">
            <div class="directorAction">
              <div class="directorName">{{ selectedRow.name }}</div>
              <div v-if="selectedRow.id " class="update">
                <el-popover
                  placement="left-start"
                  width="300"
                  @show="handleUpdateDirectoryShow"
                  v-model="updatePopoverVisible">
                  <el-input :maxlength="10" :minlength="2" v-model="directoryName" placeholder="请输入目录名称"></el-input>
                  <div style="margin-top: 10px; text-align: right;">
                    <el-button @click="updatePopoverVisible = false, directoryName = undefined">取消</el-button>
                    <el-button type="primary" @click="handleUpdateDirectoryConfirm">确认修改</el-button>
                  </div>
                  <el-button slot="reference"  type="text">修改</el-button>
                </el-popover>
              </div>
              <div v-if="selectedRow.id" class="del">
                <el-popconfirm
                    title="您确定要删除该条数据吗?"
                    @confirm="handleDeleteDirectory"
                  >
                  <el-button slot="reference" type="text" style="color: red;">删除</el-button>
                </el-popconfirm>
              </div>
            </div>
            <div class="searchWrap">
              <el-input class="ipt" v-model="name" placeholder="输入物料名称"></el-input>
              <el-button type="primary">搜索</el-button>
            </div>
          </div>
          <div class="materialWrap">
            <div 
              v-for="(item, index) in materialList" 
              :key="index" 
              class="materialItem"
              @click="handleSelect(item)">
              <img :src="item.url">
            </div>
          </div>
          <div class="pagenationWrap">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-size="20"
              layout="total, prev, pager, next, jumper"
              :total="total">
            </el-pagination>
          </div>
        </el-col>
      </el-row>
      <div class="bottom">
        <el-popover
          placement="left-start"
          width="300"
          v-model="popoverVisible">
          <el-input :maxlength="10" :minlength="2" v-model="directoryName" placeholder="请输入目录名称"></el-input>
          <div style="margin-top: 10px; text-align: right;">
            <el-button @click="popoverVisible = false, directoryName = undefined">取消</el-button>
            <el-button type="primary" @click="handleAddDirectoryConfirm">确认添加</el-button>
          </div>
          <el-button slot="reference">添加目录</el-button>
        </el-popover>
        <el-upload
          class="upload-demo"
          accept=".png,.jpg,.jpeg"
          action="/business/upload"
          :show-file-list="false"
          :before-upload="beforeUpload"
          :on-success="handleSuccess"
          :http-request="(file) =>handleUploadFile(file.file)">
          <el-button type="primary" icon="el-icon-upload">上传图片</el-button>
        </el-upload>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'MaterialDialog',
  components: {
  },
  props: {
    visible: Boolean
  },
  data () {
    return {
      directoryList: [],
      selectedIndex: 0,
      materialList: [], // 物料列表
      name: '', // 物料名称
      directoryName: '', // 目录名称
      popoverVisible: false,
      /* 分页 */
      currentPage: 1,
      pageSize: 20,
      total: 0,
      selectedRow: {},
      /* 文件上传 */
      fileList: [],
      updatePopoverVisible: false
    }
  },
  created () {
    this.handleGetMaterialList()
    this.handleGetDirectoryList()
  },
  methods: {
    /* 上传图片 */
    beforeUpload(file) {
      const isJPGorPNG = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJPGorPNG) {
        this.$message.error('上传图片只能是 JPG 或 PNG 格式!');
      }
      return isJPGorPNG;
    },
    async handleUploadFile (file) {
      const formData = new FormData()
      formData.append('file', file)
      const { data } = await this.$api.sysapi.uploadFile(formData, 'material')
      const { id, name } = this.selectedRow
      const postData = {
        name,
        pid: id,
        url: data
      }
      await this.$api.shopManageApi.addMaterial(postData)
      await this.handleGetMaterialList()
    },
    handleSuccess(response, file) {
      this.$message.success('上传成功');
    },
    /* 分页 */
    handleCurrentChange (val) {
      this.currentPage = val
    },
    /* 目录 */
    // 目录列表
    async handleGetDirectoryList () {
      const postData = {
        name: '',
        pageNum: 1,
        pageSize: -1,
        pid: 0
      }
      const { code, data, msg } = await this.$api.shopManageApi.directoryList(postData)
      if (code == 200) {
        this.directoryList = [{name: '全部', id: '', pid: 0 }]
        this.directoryList.push(...data.list)
      } else {
        this.$message.error(res.msg);
      }
    },
    // 新增目录
    async handleAddDirectoryConfirm () {
      if (!this.directoryName.trim()) {
        this.$message.error('请填写目录名称')
        return
      }
      const postData = {
        name: this.directoryName,
        pid: 0
      }
      const { code, msg } = await this.$api.shopManageApi.addDirectory(postData)
      if (code == 200) {
        this.$message.success('添加成功');
        this.directoryName = ''
        this.popoverVisible = false
        await this.handleGetDirectoryList()
      } else {
        this.$message.error('添加失败：' + msg);
      }
    },
    // 修改目录
    async handleUpdateDirectoryConfirm () {
      if (!this.directoryName.trim()) {
        this.$message.error('请填写目录名称')
        return
      }
      const postData = {
        name: this.directoryName,
        id: this.selectedRow.id
      }
      const { code, msg } = await this.$api.shopManageApi.updateDirectory(postData)
      if (code == 200) {
        this.$message.success('修改成功');
        this.directoryName = ''
        this.updatePopoverVisible = false
        await this.handleGetDirectoryList()
      } else {
        this.$message.error('修改失败：' + msg);
      }
    },
    // 修改目录pop显示的时候的逻辑
    handleUpdateDirectoryShow () {
      this.directoryName = this.selectedRow.name 
    },
    // 删除目录
    async handleDeleteDirectory () {
      const postData = {
        id: this.selectedRow.id
      }
      const { code, msg } = await this.$api.shopManageApi.deleteDirectory(postData)
      if (code == 200) {
        this.$message.success('删除成功');
        this.popoverVisible = false
        await this.handleGetDirectoryList()
        await this.handleSelectdirectory({name: '全部', id: '', pid: 0 }, 0)
      } else {
        this.$message.error('删除失败：' + msg);
      }
    },
    // 选择目录
    async handleSelectdirectory (row, index) {
      this.selectedIndex = index
      this.selectedRow = row
      this.currentPage = 1
      await this.handleGetMaterialList()
    },
    // 查询物料列表
    async handleGetMaterialList () {
      const postData = {
        id: this.selectedRow.id,
        pageNum: this.currentPage,
        pageSize: this.pageSize
      }
      const { code, msg, data } = await this.$api.shopManageApi.getMaterialByDirectory(postData)
      if (code == 200) {
        this.materialList = data.list
        this.total = data.total
      } else {
        this.$message.error(msg);
      }
    },
    // 选择物料
    handleSelect (row) {
      this.$emit('handleTypeImg', row.url)
      this.$emit('update:visible', false)
    },
    // handle
    handleClose () {
      this.$emit('update:visible', false)
    },
  }
}
</script>
<style lang="scss" scoped>
.directoryList {
  padding: 10px;
  .directoryItem{
    width: 100%;
    height: 30px;
    line-height: 30px;
    cursor: pointer;
    &.active {
      color: #605FFE;
      border-right: 4px solid #605FFE;
    }
    // &.active::after {
    //   content: '';
    //   position: absolute;
    //   left: 50%;
    //   top: 0;
    //   transform: translate(-50%);
    //   width: 4px;
    //   height: 30px;
    //   background: #ea5d1f;
    // }
  }
}
.rightWrap{
  .top{
    width: 100%;
    display: flex;
    .directorAction{
      flex: 1;
      text-align: left;
      display: flex;
      align-items: center;
      .directorName{
        margin-right: 10px;
      }
      .update,.del{
        margin-right: 10px;
      }
    }
    .searchWrap{
      flex: 1;
      text-align: right;
      .ipt{
        width: 150px;
        margin-right: 10px;
      }
    }
  }
  .materialWrap {
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    .materialItem{
      display: flex;
      align-items: center;
      box-sizing: border-box;
      width: 20%;
      height: 125px;
      padding: 10px;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
}
.bottom{
  display: flex;
}
</style>
